$font-family: 'Inter', sans-serif;
$border-color: #eaeaea;
$hover-bg: #f5f5f5;
$header-bg: #f7f5f0;

// .table {
//   width: 100%;
//   border-collapse: collapse;
//   font-family: $font-family;
//   color: #333;
//   border-radius: 8px;
//   overflow: hidden;
//   font-weight: 500;

//   .tr {
//     background-color: $header-bg !important;
//     &:first-child {
//       .th {
//         background-color: $header-bg !important;
//         border-top: 1px solid $border-color;
//         &:first-child {
//           border-top-left-radius: 8px;
//           border-left: 1px solid $border-color;
//           text-align: center;
//         }
//         &:last-child {
//           border-top-right-radius: 8px;
//           border-right: 1px solid $border-color;
//         }
//       }
//     }

//     &:hover {
//       background-color: $hover-bg;
//     }
//   }

//   tr {
//     position: relative;
//     background-color: #fff;
    
//     &>td {
//       font-size: 18px;
//       color: #c5c5c5;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       height: 100%;
//     }
//   }

//   .th {
//     text-align: left;
//     padding: 12px;
//     font-weight: bold;
//     color: #000;
//     border-bottom: 1px solid $border-color;
//     display: flex;
//     align-items: center;
//     p {
//       color: #000;
//     }
//   }

//   .td {
//     padding: 12px;
//     border-bottom: 1px solid $border-color;
//     border-right: 1px solid $border-color;
//     display: flex;
//     align-items: center;

//     &:first-child {
//       text-align: center;
//       justify-content: center;
//     }
//   }
// }

// .badge {
//   display: inline-block;
//   padding: 4px 8px;
//   border-radius: 12px;
//   font-size: 12px;
//   font-weight: bold;

//   &.red {
//     background-color: #ffe6e6;
//     color: #d9534f;
//   }

//   &.orange {
//     background-color: #fff3e6;
//     color: #f0ad4e;
//   }

//   &.yellow {
//     background-color: #fff9e6;
//     color: #f7c948;
//   }

//   &.green {
//     background-color: #e6ffe6;
//     color: #5cb85c;
//   }
// }

// .checkbox-column {
//   width: auto;

//   input[type='checkbox'] {
//     transform: scale(1.2);
//   }
// }

// .row-number {
//   width: auto;
// }

// .case-column {
//   .badge {
//     background-color: transparent !important;
//   }
// }

// @media (max-width: 768px) {
//   .table {
//     .td,
//     .th {
//       padding: 8px;
//     }
//   }

//   .badge {
//     font-size: 10px;
//   }
// }

.resizer {
  display: inline-block;
  width: 1px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  touch-action: none;
  background: #e0e0e0;
  &:hover {
    background: #e0e0e0;
    width: 8px;
  }

  &.isResizing {
    background: #2196f3;
  }
}

.dashboard-sheet-container {
  position: relative;
  .add-column-button-container {
    position: absolute;
    right: -120px;
    top: 40px;
    z-index: 1000;
  }
}

.table .td {
  margin-left: 0px !important;
  padding-left: 8px;
}