  .dashboard-references-text-container {
      .references-container {
        padding: 12px 24px;
        font-family: 'Inter', sans-serif;
        font-size: 18px;
        line-height: 1.5;
        span.reference-id {
            padding: 4px 8px;
            border-radius: 12px;
            font-size: 14px;
            font-weight: bold;
            background-color: #e6e6e6;
            color: #333;
            margin-left: 6px;
            cursor: pointer;
          }
      }
  }

  .dashboard-references-doc-preview {
      .docx {
          width: 100% !important;
          max-width: 968px !important;
      }
  }