.search-input-container {
  width: 100%;
  position: relative;
  .search-submit-button {
    position: absolute;
    right: 10px;
  }
  .search-input {
    width: 100%;
  }
}